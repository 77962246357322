import { IconAboveText, IconProposalPartner, IconJobNonPublic } from '../../components';
import css from './SearchPage.module.css';
import { useTranslation } from '../../hooks';
import { SectionJobHero } from './SectionJobHero';
import React from 'react';

export const SectionJobCta = () => {
  const setTranslation = useTranslation('SearchPage');
  const title = setTranslation('searchPageHeroTitle');
  const ctaText = setTranslation('searchPageCtaText');
  const ctaRouteName = 'NewListingPage';
  const publicText = setTranslation('searchPagePublicText');
  const privateText = setTranslation('searchPagePrivateText');


  return (
    <section className={css.landingSection}>
      <SectionJobHero title={title} ctaText={ctaText} ctaRouteName={ctaRouteName}>
        {setTranslation('searchPageHeroPromoText', {br: <br/>})}
      </SectionJobHero>

      <div className={css.jobTypesContainer}>
        <IconAboveText icon={<IconProposalPartner />} text={publicText} />
        <IconAboveText icon={<IconJobNonPublic />} text={privateText} />
      </div>

    </section>
  );
};
